<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">
            <router-link :to="{name: 'AccountExports'}" class="subtitle-1 routerLink font-weight-medium">Exportar</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>Notas de crédito
          </v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 pb-0">
          <v-row class="px-4">
            <v-col cols="12" sm="10" md="9">
              <span class="body-2 grey-500--text font-weight-medium">Filtrar registros</span>
              <v-query-builder :flat="true" :query-map="filters" filter-text="Previsualizar" model="credit_note" />
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col>
              <span class="body-2 grey-500--text font-weight-medium">Previsualización</span>
            </v-col>
          </v-row>
          <v-divider />
          <credit-note-list :show-pagination="false" />
          <v-divider />
          <v-row class="px-4">
            <v-col>
              <span class="body-2 grey-500--text font-weight-medium">{{count}} registros</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="createExport" :loading="$store.state.accounts.createLoader" color="blue-500">Exportar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import DocumentTypes from '@/collections/documentTypes'
import CreditNoteList from '@/modules/documents/components/CreditNoteList'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  components: {
    VQueryBuilder,
    CreditNoteList
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    isPreview: false,
    filters: [
      {
        type: 'text',
        id: 'documents__customer__name',
        label: 'Cliente',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'date',
        label: 'Fecha',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'numeric',
        id: 'number',
        label: 'Número',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'select',
        id: 'document',
        label: 'Tipo de nota de crédito',
        choices: []
      },
      {
        type: 'text',
        id: 'note',
        label: 'Nota',
        operators: ['contiene']
      },
      {
        type: 'numeric',
        id: 'total',
        label: 'Total',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'numeric',
        id: 'documents__number',
        label: 'Número de la factura',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'text',
        id: 'documents__date',
        label: 'Fecha de la factura',
        operators: ['es igual', 'es menor a', 'es mayor a']
      },
      {
        type: 'select',
        id: 'documents__document',
        label: 'Tipo de la factura asociada',
        choices: []
      },
      {
        type: 'select',
        id: 'documents__collection_method',
        label: 'Forma de pago de la factura',
        choices: [
          { label: 'Contado', value: 'automatically' },
          { label: 'Crédito', value: 'deferred' }
        ]
      },
      {
        type: 'radio',
        id: 'documents__subscription__isnull',
        label: 'Suscripción',
        choices: [
          { label: 'Si', value: 'False' },
          { label: 'No', value: 'True' }
        ]
      }
    ],
    documentsList: DocumentTypes
  }),
  computed: mapState({
    count: state => state.documents.creditnotesCount
    // documentsList: state => state.documents.creditnotesList
  }),
  created () {
    // this.$store.dispatch('base/LIST', {
    //   resource: 'base/document_types',
    //   query: {
    //     country__id__in: `${this.account.country.id}, US`,
    //     is_sale: 'True'
    //   }
    // })
    // .then((response) => {
      const currentDocumentType = this.documentTypes.find(item => item.country === this.account.country.id)
      if (currentDocumentType !== undefined) {
        const index = this.filters.findIndex(filter => filter.id === 'documents__document')
        this.filters[index].choices = [{label: currentDocumentType.name, value: currentDocumentType.id}]
      }
      // if (response.data.results && response.data.results.length) {
      //   const index = this.filters.findIndex(filter => filter.id === 'documents__document')
      //   this.filters[index].choices = response.data.results.map((document) => {
      //     return { label: document.name, value: document.id }
      //   })
      // }
    // })
    this.$store.dispatch('base/LIST', {
      resource: 'base/document_types',
      query: {
        country__id__in: `${this.account.country.id}, US`,
        is_sale: 'False',
        is_purshase: 'False',
        is_order: 'False'
      }
    })
    .then((response) => {
      if (response.data.results && response.data.results.length) {
        const index = this.filters.findIndex(filter => filter.id === 'document')
        this.filters[index].choices = response.data.results.map((document) => {
          return { label: document.name, value: document.id }
        })
      }
    })
  },
  methods: {
    getList () {
      this.$store.dispatch('documents/LIST', {
        resource: 'creditnotes',
        query: Object.assign({}, this.$route.query, {
          page_size: 10,
          expand: 'document,documents'
        })
      })
    },
    str2bytes (str) {
      var bytes = new Uint8Array(str.length)
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i)
      }
      return bytes
    },
    createExport () {
      this.$store.dispatch('accounts/LIST', {
        resource: 'exports/creditnotes',
        query: this.$route.query,
        loader: true,
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        let blob = await new Blob([response.data], { type: 'application/zip' })
        saveAs(blob, 'credit_notes.zip')
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
    }
  }
}
</script>